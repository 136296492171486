//header_area css
.header_area{
    position: absolute;
    width: 100%;
    top: 0;
    left:0;
    z-index: 99;
    transition: background 0.4s, all 0.3s linear;
    .navbar{
        background: transparent;
        padding: 0px;
        border: 0px;
        border-radius: 0px;
        .nav{
            .nav-item{
				margin-right: 45px;
                .nav-link{
                    font: 500 12px/80px $rob;
                    text-transform: uppercase;
                    color: $dip;
                    padding: 0px;
                    display: inline-block;
                    &:after{
                        display: none;
                    }
                }
                &:hover, &.active{
                    .nav-link{
                        color: $baseColor;
                    }
                }
                &.submenu{
                    position: relative;
                    ul{
                        border: none;
                        padding: 0px;
                        border-radius: 0px;
                        box-shadow: none;
                        margin: 0px;
                        background: #fff;
						box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
                        @media (min-width: 992px){
                            position: absolute;
                            top: 120%;
                            left: 0px;
                            min-width: 200px;
                            text-align: left;
                            opacity: 0;
                            transition: all 300ms ease-in;
                            visibility: hidden;
                            display: block;
                            border: none;
                            padding: 0px;
                            border-radius: 0px;
                        }
                        &:before{
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 10px 10px 0 10px;
                            border-color: #eeeeee transparent transparent transparent;
                            position: absolute;
                            right: 24px;
                            top: 45px;
                            z-index: 3;
                            opacity: 0;
                            transition: all 400ms linear;
                        }
                        .nav-item{
                            display: block;
                            float: none;
                            margin-right: 0px;
                            border-bottom: 1px solid #ededed;
                            margin-left: 0px;
                            transition: all 0.4s linear;
                            .nav-link{
                                line-height: 45px;
                                color: $dip;
                                padding: 0px 30px;
                                transition: all 150ms linear;
                                display: block;
                                margin-right: 0px;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                            &:hover{
                                .nav-link{
                                    background: $baseColor;
                                    color: #fff;
                                }
                            }    
                        }
                    }
                    &:hover{
                        ul{
                            @media (min-width: 992px){
                                visibility: visible;
                                opacity: 1;
                                top: 100%;
                            }
                            .nav-item{
                                margin-top: 0px;
                            }
                        }
                    }
                }
				&:last-child{
					margin-right: 0px;
				}
            }
        }
        .search{
            font-size: 12px;
            line-height: 60px;
            display: inline-block;
            color: $dip;
            margin-left: 80px;
            i{
                font-weight: 600;
            }
        }
    }
	& + section, & + row, & + div{
		margin-top: 122px;
	}
	&.navbar_fixed{
		.main_menu{
			position: fixed;
			width: 100%;
			top: -70px;
			left: 0;
			right: 0;
			background: #fff;  
			transform: translateY(70px);
			transition: transform 500ms ease, background 500ms ease;
			-webkit-transition: transform 500ms ease, background 500ms ease;
			box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
			.navbar{
				.nav{
					.nav-item{
						.nav-link{
							line-height: 70px;
						}
					}
				}
			}
		}
	}
}

.top_menu{
	background: #ffffff;
	.header_social{
		li{
			display: inline-block;
			margin-right: 15px; 
			a{
				font-size: 14px;
				color: $pfont;
				display: inline-block;
				line-height: 42px;
				@include transition;
			}
			&:last-child{
				margin-right: 0px;
			}
			&:hover{
				a{
					color: $baseColor;
				}
			}
		}
	}
	.dn_btn{
		line-height: 42px;
		display: inline-block;
		font-size: 12px;
		margin-right: 30px;
		font-family: $rob;
		font-weight: normal;
		color: $pfont;
		@include transition;
		&:hover{
			color: $baseColor;
		}
		&:last-child{
			margin-right: 0px;
		}
	}
	.lan_pack{
		height: 30px;
		border: 1px solid #eeeeee;
		border-radius: 3px;
		line-height: 28px;
		font-size: 12px;
		font-family: $rob;
		font-weight: 500;
		padding-left: 19px;
		padding-right: 36px; 
		color: $pfont; 
		background: #FFFFFF;
		margin-right: 5px;
		margin-top: 8px;
		.current{
			color: $pfont;
		}
		&:after{
			content: "\f0d7";
			border: none !important;
			font: normal normal normal 12px/1 FontAwesome;
			transform: rotate(0deg);
			height: auto;
			margin-top: -6px;
			right: 20px;
		}
	}
}
