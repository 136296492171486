// if you'd like to use it without importing all of bootstrap it requires
@import "../node_modules/bootstrap/scss/bootstrap";


/* You can add global styles to this file, and also import other style files */
@import "scss/style.scss";

@font-face {
    font-family: 'Arial';
    src: url(../src/assets/fonts/arial.ttf) ;
}

.capitalize {
    text-transform: capitalize !important;
}

.mat-tab-body-content {
    overflow: hidden !important;
}

.mat-radio-button .mat-radio-outer-circle {
    border:none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-radio-label {
    white-space:unset!important;
}
